import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CellProps, Row } from 'react-table'
import { useTranslation } from 'react-i18next'

import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

import {
  DataGridCell,
  DataGridColumn,
} from '@app/components/atoms/DataGrid/DataGrid'

import GridView, {
  GridViewProps,
} from '@app/components/molecules/GridView/GridView'

import { PartialRequestDto } from '@shared/dto/requests.dto'
import { OfferFlags, OfferStatuses } from '@shared/enums'
import { useDispatch } from 'react-redux'

import useUnreadMessages from '@app/hooks/useUnreadMessagesCount'
import NewMessageIcon from '@app/components/atoms/NewMessageIcon/NewMessageIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  reloadRequestsListAction,
  resetRequestsListFiltersAction,
  setRequestsListFiltersAction,
} from '@app/store/pages/requests/requestList/requestList.actions'
import { useSnackbar } from 'notistack'
import { AdjustedQuoteCell } from '@app/components/molecules/RequestsGridView/Cells/AdjustedQuoteCell'
import {
  AircraftCountCell,
  FlagsCell,
  RequestedByCell,
  StatusCell,
  SummaryCell,
  TripIdCell,
  WarningCell,
} from '@app/components/molecules/RequestsGridView/Cells'
import TripInfoCell from '@app/components/molecules/RequestsGridView/Cells/TripInfoCell'
import EmptyListMessage from '@app/components/atoms/EmptyListMessage/EmptyListMessage'
import NoBookingsIcon from '@app/components/atoms/icons/NoBookingsIcon/NoBookingsIcon'
import { Box, Checkbox } from '@material-ui/core'
import Button from '@app/components/atoms/Button/Button'

import { api } from '@app/utils/api/api'

import * as DTOs from '@shared/dto'
import { openConfirmationDialogAction } from '@app/store/ui/confirmationDialog/confirmationDialog.actions'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { RequestPageVariants } from '@app/components/pages/Requests/Requests'

export interface RequestsGridViewProps
  extends Omit<
    GridViewProps<PartialRequestDto>,
    'columns' | 'dataTestIdNamespace' | 'getBadgeNotificationContent'
  > {
  onGetInitialData?: () => void
  variant?: RequestPageVariants
}

const RequestsGridView = ({
  onGetInitialData,
  ...props
}: RequestsGridViewProps): JSX.Element => {
  const { t } = useTranslation()
  const { data: unreadMessages, markAllMessagesAsRead } = useUnreadMessages()
  const anchorEl = useRef(null)
  const [showUnreadMenu, setShowUnreadMenu] = useState(false)
  const [checkedRequests, setCheckedRequests] = useState<
    { id: number; status: OfferStatuses }[]
  >([])
  const [gridVariant] = useState(props.variant)
  const [bulkDeclineAvailable, setBulkDeclineAvailable] =
    useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const handleUnreadMessagesFilter = useCallback(() => {
    setShowUnreadMenu(false)
    dispatch(resetRequestsListFiltersAction())
    dispatch(
      setRequestsListFiltersAction(
        {
          flags: [OfferFlags.NewMessage],
        },
        100,
      ),
    )
  }, [])

  const handleMarkAllMessagesAsRead = useCallback(async () => {
    const response = await markAllMessagesAsRead()
    setShowUnreadMenu(false)

    if (response.success) {
      enqueueSnackbar(response.message, {
        variant: 'success',
      })
      window.location.reload()
    } else {
      enqueueSnackbar(response.message, {
        variant: 'error',
      })
    }
  }, [])

  const handleRequestCheckboxClicked = useCallback(
    (row: Row<DTOs.PartialRequestDto>) => {
      setCheckedRequests((prevValues) => {
        if (prevValues.some((curRow) => curRow.id === row.original.id)) {
          return prevValues.filter((curReq) => curReq.id !== row.original.id)
        } else {
          return [
            { id: row.original.id, status: row.original.status },
            ...prevValues,
          ]
        }
      })
    },
    [],
  )

  useEffect(() => {
    setBulkDeclineAvailable(
      checkedRequests.length > 0 &&
        checkedRequests.every((curItem) =>
          [
            OfferStatuses.New,
            OfferStatuses.Unhandled,
            OfferStatuses.Draft,
          ].includes(curItem.status),
        ),
    )
  }, [checkedRequests])

  const handleBulkDeclineClick = () => {
    dispatch(
      openConfirmationDialogAction({
        shouldCloseOnSubmit: true,
        i18nextKey: t('confirmations.bulkDeclineConfirmation'),
        onSubmit: handleBulkDeclineSubmit,
      }),
    )
  }

  const handleBulkDeclineSubmit = () => {
    const requestIds = checkedRequests.map((curReq) => curReq.id)
    api.markMessagesAsRead({ request_ids: requestIds })
    api
      .bulkDeclineRequests(requestIds)
      .then(() => {
        dispatch(reloadRequestsListAction())
        setCheckedRequests([])
        dispatch(
          addNotificationAction({
            i18nextKey: 'messages.declineRequests.success',
            type: 'success',
          }),
        )
      })
      .catch(() => {
        dispatch(
          addNotificationAction({
            i18nextKey: 'errors.declineRequests.default',
            type: 'error',
          }),
        )
      })
  }

  const columns = React.useMemo<DataGridColumn<PartialRequestDto>[]>(() => {
    const cols = [
      {
        id: 'request-checkbox',
        title: '',
        Cell: ({ row }) => (
          <StyledCheckbox
            checked={checkedRequests.some(
              (curReq) => curReq.id === row.original.id,
            )}
            onChange={() => {
              handleRequestCheckboxClicked(row)
            }}
          />
        ),
      },
      {
        id: 'created_at',
        title: 'Status / Created',
        Cell: StatusCell,
        width: '0',
        isSortable: true,
      },
      {
        id: 'flags',
        title: 'Flags',
        Cell: FlagsCell,
        width: '0',
        addorment:
          unreadMessages > 0 ? (
            <StyledHeaderTooltip
              title={t('enums.OfferFlags.newMessages') as string}
            >
              <div>
                <StyledNewMessageIcon
                  onClick={() => setShowUnreadMenu(true)}
                  ref={anchorEl}
                />
                <StyledMenu
                  anchorEl={anchorEl.current}
                  open={showUnreadMenu}
                  onClose={() => setShowUnreadMenu(false)}
                >
                  <MenuItem onClick={handleUnreadMessagesFilter}>
                    <ListItemIcon>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t('requests.filterUnreadMessages')}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleMarkAllMessagesAsRead}>
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t('requests.markAllMessagesAsRead')}
                    </ListItemText>
                  </MenuItem>
                </StyledMenu>
              </div>
            </StyledHeaderTooltip>
          ) : undefined,
      },
      {
        id: 'requested_by',
        title: 'Requested by',
        Cell: RequestedByCell,
        isSortable: true,
      },
      {
        id: 'trip_date',
        title: 'Trip information',
        Cell: TripInformationCell,
        isSortable: true,
      },
      {
        id: 'trip_id',
        title: 'Trip ID',
        Cell: TripIdCell,
        isSortable: true,
      },
      {
        id: 'aircraft_count',
        title: 'Aircraft count',
        Cell: AircraftCountCell,
        width: '6rem',
      },
      {
        id: 'total_profit',
        title: 'Aircraft / Profit & Price',
        Cell: SummaryCell,
        isSortable: true,
      },
      {
        id: 'adjusted_quote',
        title: 'Adjusted Quote by Schedule Change',
        Cell: AdjustedQuoteCell,
      },
      {
        id: 'warnings',
        title: 'Warnings',
        Cell: WarningCell,
      },
    ]

    if (gridVariant === RequestPageVariants.Bookings) {
      cols.shift()
    }
    return cols
  }, [
    unreadMessages,
    showUnreadMenu,
    anchorEl,
    handleUnreadMessagesFilter,
    checkedRequests,
    gridVariant,
  ])

  useEffect(() => {
    onGetInitialData?.()
  }, [])

  return (
    <>
      {gridVariant !== RequestPageVariants.Bookings && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={!bulkDeclineAvailable}
            onClick={handleBulkDeclineClick}
            data-testid="LegEditorForm__ignore-legs"
          >
            {t('organisms.RequestsList.bulkRequestDecline')}
          </Button>
        </Box>
      )}
      <StyledGridView<PartialRequestDto>
        columns={columns}
        dataTestIdNamespace="RequestsGridView"
        getBadgeNotificationContent={(request) =>
          (request as PartialRequestDto).unread_messages_count
        }
        noDataContent={
          <EmptyListMessage
            title={t('molecules.RequestsGridView.noDataTitle')}
            description={t('molecules.RequestsGridView.noDataDescription')}
            Icon={NoBookingsIcon}
          />
        }
        {...props}
      />
    </>
  )
}

const TripInformationCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => {
  return <TripInfoCell tripInfo={cell.row.original.trip_info} />
}

const StyledMenu = styled(Menu)`
  margin-top: 20px;
  margin-left: 20px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`

const StyledGridView = styled(GridView)`
  ${DataGridCell} {
    padding: 0.5rem 1rem;
  }
`

const StyledNewMessageIcon = styled(NewMessageIcon)`
  transform: translate(0.5rem, 0.3rem);
  width: 1rem;
  height: 1rem;

  &:hover {
    cursor: pointer;
  }
`

const StyledHeaderTooltip = styled(Tooltip)`
  display: inline-block;
  min-width: 2rem;
`

export default RequestsGridView
